
import { Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import { CampaignModule, HomeModule, CreateModule } from '@/store';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import add from 'date-fns/add';

import CampaignCard from '@/components/cards/campaignCard.vue';
import ImageTextCard from '@/components/cards/imageTextCard.vue';
import PostDetailSidebar from '@/components/sidebars/postDetailSidebar.vue';
import WeeklyPosts from '@/components/weeklyPosts.vue';
import WeeklyPostsMob from '@/components/weeklyPostsMob.vue';
import AuthoriseSocialChannelsModal from '@/components/modals/authoriseSocialChannelsModal.vue';
import CreatePostSuccessModal from '@/components/modals/createPostSuccessModal.vue';
import StoreSwitcherMob from '@/components/storeSwitcherMob.vue';
import { SidebarMixin } from '@/mixins/sidebarMixin';

// types
import { CreateSubmitTypes } from '@/models/createSubmitTypes';
import { PostListItem } from '@/models/posts/postListItem';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';

const campaignModule = getModule(CampaignModule);
const homeModule = getModule(HomeModule);
const createModule = getModule(CreateModule);

@Component({
  components: {
    ImageTextCard,
    CampaignCard,
    WeeklyPosts,
    WeeklyPostsMob,
    PostDetailSidebar,
    AuthoriseSocialChannelsModal,
    CreatePostSuccessModal,
    StoreSwitcherMob,
  },
  mixins: [SidebarMixin],
})
export default class HomeIndex extends LoggedInComponentBase {
  public showPostDetailSidebar: boolean = false;
  public showCategories: boolean = false;
  public selectedPost: PostListItem | null = null;
  public isCreatePostSuccessModalVisible: boolean = false;

  private get isPostUpdated(): boolean {
    return this.postModalModule.isPostUpdated;
  }

  public get campaigns() {
    return homeModule.campaigns;
  }

  public get currentCampaignCategory(): CampaignCategory {
    return campaignModule.currentCampaignCategory;
  }

  public get dailyPosts() {
    return homeModule.dailyPosts;
  }

  public get endDay() {
    return add(new Date(homeModule.endDay), { weeks: 1 });
  }

  public get startDay() {
    return new Date(homeModule.startDay);
  }

  public get spotlightCampaigns() {
    return homeModule.highlightCards;
  }

  public get supplierCampaigns() {
    return homeModule.supplierCampaigns;
  }

  private async created(): Promise<void> {
    await this.checkSocialChannelsAuthorised();
  }

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: HomeIndex) => void)) => void
  ): Promise<void> {
    try {
      await homeModule.getHomeData();
      next();
    } catch (err) {
      next(false);
    }
  }

  mounted() {
    // show /create-post success feedback
    if (createModule.submitSuccess === CreateSubmitTypes.POST) {
      this.isCreatePostSuccessModalVisible = true;
      createModule.setSubmitSuccess(null);
    }
  }

  public async handlePostUpdated() {
    await homeModule.getHomeData();
    this.showPostDetailSidebar = false;
  }

  public showEvent({ nativeEvent, event }: { nativeEvent: any; event: any }) {
    const eventType = event.eventType.toLowerCase();

    if (eventType === 'campaigns') {
      this.$router.push({
        name: 'creativecentre.campaignDetail',
        params: { category: event.categoryId, id: event.id },
      });
    } else {
      this.selectedPost = event;
      this.showPostDetailSidebar = true;
    }
    nativeEvent?.stopPropagation();
  }

  @Watch('isPostUpdated')
  private async onPostUpdated(): Promise<void> {
    if (this.isPostUpdated) {
      await this.handlePostUpdated();
    }
  }

  @Watch('currentStore')
  public async onStoreChanged() {
    await homeModule.getHomeData();

    this.showPostDetailSidebar = false;
    this.selectedPost = null;
  }
}
