
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { format, isToday, isBefore, startOfToday } from 'date-fns';
import Swiper from 'swiper';
import { EventTypesLabels } from '@/constants';
import { DailyPostsGroup } from '@/models/posts/dailyPostsGroup';
import { PostListItem } from '@/models/posts/postListItem';
import PostPreview from '@/components/cards/postPreview.vue';
import { getDisplayedDate } from '@/services/eventFactory';
import PostPreviewEmpty from '@/components/cards/postPreviewEmpty.vue';

@Component({
  components: {
    PostPreview,
    PostPreviewEmpty,
  },
  methods: {
    getDisplayedDate,
  },
})
export default class DailyPosts extends LoggedInComponentBase {
  @Prop() posts?: DailyPostsGroup[];
  @Prop() startDay?: Date;
  @Prop() endDay?: Date;
  @Prop() showCalendarLink?: boolean;

  public swiper: Swiper | null = null;
  public allowSlideToPrev: boolean = true;
  public allowSlideToNext: boolean = true;

  private mounted() {
    this.swiper = new Swiper(this.$refs.swiper as HTMLElement, {
      slidesPerView: 'auto',
      watchSlidesProgress: true,
      threshold: 20,
      initialSlide: this.initialSlide,
      watchOverflow: false,
      on: {
        init: (swiper) => {
          this.allowSlideToPrev = !swiper.isBeginning;
          this.allowSlideToNext = !swiper.isEnd;
        },
        slideChange: (swiper) => {
          this.allowSlideToPrev = !swiper.isBeginning;
          this.allowSlideToNext = !swiper.isEnd;
        },
      },
    });
  }

  public slideDays(days: number) {
    if (this.swiper) {
      const targetPosition = this.swiper!.activeIndex + days;
      this.swiper!.slideTo(targetPosition);
    }
  }

  private get initialSlide(): number {
    return this.posts!.findIndex((post) => isToday(post.date));
  }

  public get displayCalendarLink(): boolean {
    return this.showCalendarLink || false;
  }

  /**
   * If a `post` has used a `Recommended Post` as a template,
   * we don't want to show that `Recommended Post` in the sidebar.
   * */
  public removeUsedRecommendedPosts(posts: PostListItem[]): PostListItem[] {
    const result: PostListItem[] = [];
    posts.forEach((post) => {
      if (post.postType === EventTypesLabels.RecommendedPosts) {
        const recommendedPostId = post.id;
        if (
          !posts.some((x) => {
            return x.usedTemplateId === recommendedPostId;
          })
        ) {
          result.push(post);
        }
      } else if (!result.some((result) => result.id === post.id)) {
        result.push(post);
      }
    });
    return result;
  }

  public createNewPost(group: DailyPostsGroup) {
    const dateClicked = group.date;

    this.createPostModule.setScheduledDate(format(dateClicked, 'yyyy-MM-dd'));
    this.createPostModule.setScheduledTime(format(dateClicked, 'K:mm'));

    if (!isToday(dateClicked)) {
      this.createPostModule.setIsScheduledImmediately(false);
    }

    this.$router.push({ path: '/create-post' });
  }

  public showPost(post: PostListItem) {
    /**
     * We need to pass an object that mimics the object passed when an event on the actual calendar
     * is clicked, so we can call the same method whether we click on one of those events or on an 'event'
     * in this component.
     */
    const postType = post.postType;
    this.$emit('show-post-detail', {
      nativeEvent: null,
      event: { ...post, eventType: postType },
    });
  }

  public isBeforeToday(date: Date): boolean {
    return isBefore(date, startOfToday());
  }
}
